import * as React from 'react'

import { Box, Button, Paper, Typography } from '@mui/material'

export const CtaLaCadena = () => (
  <Paper
    variant='outlined'
    sx={{
      borderColor: 'primary.main',
      p: 4,
    }}
  >
    <Typography
      variant='h5'
      component='p'
      textAlign='center'
      sx={(theme) => ({
        pb: 3,
        fontWeight: 300,
        color: theme.palette.mode === 'light'
          ? theme.palette.primary.dark
          : theme.palette.text.primary,
      })}
    >
      Esta es una colaboración con el medio independiente <span style={{ fontWeight: 400 }}>La Cadena</span>
    </Typography>
    <Box
      display='flex'
      justifyContent='center'
    >
      <Button
        href='https://www.patreon.com/lacadena?fan_landing=true'
        target='_blank'
        rel='noopener noreferrer'
        variant='outlined'
        size='large'
        sx={{ fontSize: '1.1rem' }}
      >
        Suscríbete a La Cadena
      </Button>
    </Box>
  </Paper>
)

export default CtaLaCadena
