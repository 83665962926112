import * as React from 'react'

import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
} from '@mui/icons-material'
import { Divider, IconButton } from '@mui/material'

export const SocialShareButtons = () => (
  <Divider>
    <IconButton
      target='_blank'
      rel='noopener noreferrer'
      aria-label='Instagram de Sugarblock'
      href={'https://www.instagram.com/sugarblock.io/?hl=en'}
    >
      <Instagram />
    </IconButton>
    <IconButton
      target='_blank'
      rel='noopener noreferrer'
      aria-label='Facebook de Sugarblock'
      href={'https://www.facebook.com/Sugarblock-105366085397842'}
    >
      <Facebook />
    </IconButton>
    <IconButton
      target='_blank'
      rel='noopener noreferrer'
      aria-label='Twitter de Sugarblock'
      href={'https://twitter.com/Sugarblock_io'}
    >
      <Twitter />
    </IconButton>
    <IconButton
      target='_blank'
      rel='noopener noreferrer'
      aria-label='LinkedIn de Sugarblock'
      href={'https://www.linkedin.com/company/sugarblock/'}
    >
      <LinkedIn />
    </IconButton>
  </Divider>
)

export default SocialShareButtons
