import * as React from 'react'

import { Box, Button, Paper, Typography } from '@mui/material'

export const CtaCard = () => (
  <Paper
    variant='outlined'
    sx={{
      borderColor: 'primary.main',
      p: 4,
    }}
  >
    <Typography
      variant='h5'
      component='p'
      textAlign='center'
      sx={(theme) => ({
        pb: 3,
        fontWeight: 300,
        color: theme.palette.mode === 'light'
          ? theme.palette.primary.dark
          : theme.palette.text.primary,
      })}
    >
      Crea tu cuenta Sugarblock en 2 minutos y comienza tu aventura cripto
    </Typography>
    <Box
      display='flex'
      justifyContent='center'
    >
      <Button
        id='blog-cta'
        href='/auth/register/'
        variant='contained'
        size='large'
        sx={{ fontSize: '1.1rem' }}
      >
        Empezar en Sugarblock
      </Button>
    </Box>
  </Paper>
)

export default CtaCard
